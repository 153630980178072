.footer {
    background-color: map-deep-get($colors, "white", "default");
    color: map-deep-get($colors, "black", "default");
    position: relative;
    z-index: 15;
    flex: 1 1 100%;

    a:not(.button) {
        color: map-deep-get($colors, "black", "default");
    }

    &__bottom {
        padding-top: 30px;
        padding-bottom: 30px;

        @include media-breakpoint-up(md) {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        /* stylelint-disable */
        a:not(.button) {
            color: map-deep-get($colors, "black", "default");
        }

        /* stylelint-enable */
    }

    &__title {
        margin-bottom: 1em;
        letter-spacing: 0.2em;
    }

    &__list {
        padding-left: 1.8em;
        line-height: 36px;
    }

    &__icon {
        width: rem-calc(22);
        height: rem-calc(22);
        margin: 0 0.5em 0 -1.9em;
        display: inline-flex;
        justify-content: center;
        vertical-align: middle;

        svg {
            height: 100%;
            width: 100%;
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &__certificate {
        width: 100%;

        &--large {
            width: auto;
            max-width: 100%;
        }
    }

    &__certificates {
        margin-top: 50px;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            margin-top: 20px;
        }
    }
}
