.form-wrapper {
    background-color: map-deep-get($colors, "brand", "secondary");
    box-shadow: 0 24px 44px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 15px 20px;

    @include media-breakpoint-up(md) {
        padding: 30px 40px;
    }

    @include media-breakpoint-up(xl) {
        padding: 50px 60px;
    }

    @include media-breakpoint-up(xl) {
        &--wide {
            padding-left: 115px;
            padding-right: 115px;
        }
    }

    &__title {
        margin-top: 0;
    }
}