.hero {
    height: 70vh;
    position: relative;
    margin-left: - 10px;
    margin-right: - 10px;

    @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(map-deep-get($colors, "black", "default"), 0.6);
        }
    }

    &__bg {
        background-size: cover;
        background-position: center center;
        height: 100%;
        width: 100%;
    }

    &__content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        // webkey content
        /* stylelint-disable */
        h1, h2, h3, h4, h5, h6 {
            color: map-deep-get($colors, "white", "default");
        }
        /* stylelint-enable */
    }
}