.home-slider {
    position: relative;
    min-height: 70vh;

    $elm: &;

    &__slides {
        height: 70vh;

        // vendor overwrite
        /* stylelint-disable */
        > .flickity-viewport {
            height: 100% !important;
        }
        /* stylelint-enable */
    }

    // vendor overwrite
    /* stylelint-disable */
    .home-slide {
        position: absolute;
        top: 0;
        opacity: 0;

        &:first-child {
            opacity: 1;
        }
    }

    &__slides.flickity-enabled {
        .home-slide {
            opacity: 1;
        }
    }
    /* stylelint-enable */
}
