.block {
    padding: 30px 25px;
    border-radius: 8px;
    color: map-deep-get($colors, "white", "default");
    $elm: &;

    @include media-breakpoint-up(md) {
        padding: 60px 40px;
    }

    // webkey content
    /* stylelint-disable */
    h1:first-child, h2:first-child, h3:first-child{
        margin-top: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        color: inherit;
    }
    /* stylelint-enable */

    &--branches{
        background-color: darken(map-deep-get($colors, "brand", "primary"), 10%);
        margin-left: -#{($grid-gutter-width / 2) + 10};
        margin-right: -#{($grid-gutter-width / 2) + 10};

        @include media-breakpoint-up(md) {
            margin-left: 0;
            margin-right: 0;
        }

        @include media-breakpoint-up(xl) {
            padding: 85px 55px;
        }
    }

    &--vacancies {
        background-color: darken(map-deep-get($colors, "brand", "primary"), 10%);
        margin-top: -#{(50 + 100) * 1px};

        @include media-breakpoint-up(md) {
            margin-top: -#{(100 + 100) * 1px};
        }

        @include media-breakpoint-up(md) {
            margin-top: -#{(100 + 130) * 1px};
        }
    }

    &--filter {
        background-color: darken(map-deep-get($colors, "brand", "primary"), 10%);
    }

    &__content {
        -webkit-font-smoothing: antialiased;
    }

    &--contact {
        background-color: map-deep-get($colors, "gray", "medium");
        color: map-deep-get($colors, "black", "default");
        padding: 20px;
        margin-bottom: 20px;

        #{$elm}__header {
            color: map-deep-get($colors, "white", "default");
            margin-top: 0;
        }
    }
}