.footer-bottom {
    padding-top: 15px;
    padding-bottom: 25px;
    border-top: 1px solid map-deep-get($colors, "black", "default");
    margin-top: 30px;

    &__list-item {
        align-items: center;
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "black", "default");
        font-size: rem-calc(12);
        line-height: 1.66em;
        font-weight: 800;
        text-transform: uppercase;
        display: inline-flex;
        padding-right: 10px;

        &::after {
            content: "";
            width: 0.25em;
            height: 0.25em;
            background: map-deep-get($colors, "black", "default");
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
        }

        &:last-child::after {
            content: none;
        }
    }

    &__item {
        color: inherit;
        border-bottom: 1px solid rgba(map-deep-get($colors, "black", "default"), 0.3);
        line-height: 1em;
        transition: $transition-default;

        &:hover {
            text-decoration: none;
            border-bottom: 1px solid rgba(map-deep-get($colors, "black", "default"), 1);
        }
    }

    &__reference {
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "black", "default");
        display: block;
        font-size: 12px;
        font-weight: 800;
        margin-top: 1em;
        width: 100%;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            display: inline-block;
            margin-top: 0;
            width: auto;
        }
    }

    &__webnl {
        color: inherit;
        border-bottom: 1px solid rgba(map-deep-get($colors, "black", "default"), 0.3);
        display: inline-block;
        line-height: 1em;
        transition: $transition-default;

        &:hover {
            text-decoration: none;
            border-bottom: 1px solid rgba(map-deep-get($colors, "black", "default"), 1);
        }
    }
}