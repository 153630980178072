.section {
    margin-bottom: 50px;

    @include media-breakpoint-up(md) {
        margin-bottom: 100px;
    }

    &--double-mb {
        margin-bottom: 100px;

        @include media-breakpoint-up(md) {
            margin-bottom: 150px;
        }
    }
}