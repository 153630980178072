$hamburger-padding-x                       : 0;
$hamburger-padding-y                       : 5px;
$hamburger-layer-width                     : 24px;
$hamburger-layer-height                    : 2px;
$hamburger-layer-spacing                   : 4px;
$hamburger-layer-color                     : #ffffff;
$hamburger-layer-border-radius             : 0;
$hamburger-hover-opacity                   : 1;
$hamburger-hover-transition-duration       : .15s;
$hamburger-hover-transition-timing-function: linear;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
//

$hamburger-hover-use-filter: false;
$hamburger-hover-filter    : opacity(50%);
$hamburger-types: (squeeze);

@import "hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
    z-index: 11;
    color: map-deep-get($colors, "white", "default");
    display: flex;
    align-items: center;
    $elm: &;

    &--active {
        color: map-deep-get($colors, "black", "default");

        #{$elm}__label {
            margin-right: 1em;
        }
    }
    
    &__label {
        font-size: rem-calc(14);
        font-weight: 800;
        letter-spacing: 0.2em;
        line-height: 0.8em;
        margin-right: 0.5em;
        transition: $transition-default;
        text-transform: uppercase;
    }
}