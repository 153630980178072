.page-header {
    transition: $transition-default;
    position: relative;

    $elm: &;

    &__title {
        background: map-deep-get($colors, "brand", "secondary");
        padding: 25px;
        border-radius: 8px;
        display: inline-block;
    }

    &--pull-up {
        margin-bottom: 100px;

        @include media-breakpoint-up(md) {
            margin-bottom: 150px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 100px;
        }

        #{$elm}__title {
            position: absolute;
            transform: translateY(-50%);
            margin-top: -50px;

            @include media-breakpoint-up(md) {
                margin-top: -100px;
                padding: 40px;
            }
        }
    }
}