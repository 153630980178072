.text-link {
    $elm: &;

    &__text {
        color: map-deep-get($colors, "black", "default");
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-weight: 800;
        font-size: rem-calc(14);
        border-bottom: 1px solid rgba(map-deep-get($colors, "black", "default"), 0.25);
        line-height: 1em;
        transition: $transition-default;
        display: inline-block;
    }

    svg {
        height: 1em;
        width: 1.2em;
        fill: map-deep-get($colors, "brand", "primary");
        margin-left: 0.25em;
        transition: $transition-default;
    }

    &:hover {
        #{$elm}__text {
            color: map-deep-get($colors, "black", "default");
            text-decoration: none;
            border-bottom: 1px solid rgba(map-deep-get($colors, "black", "default"), 1);
        }

        svg {
            margin-left: 0.5em;
        }
    }
}