.form {
    overflow: hidden;
    transition: $transition-default;
    $form: &;

    &__group-wrapper {
        &--half {
            #{$form}__group:last-of-type {
                margin-bottom: rem-calc(24);
            }

            @include media-breakpoint-up(md) {
                display: flex;
                margin: 0 rem-calc(-8);

                #{$form}__group {
                    margin: 0 rem-calc(8) rem-calc(24);
                    width: 50%;
                }
            }
        }
    }

    &__group {
        margin-bottom: rem-calc(24);

        &:last-of-type {
            margin-bottom: 0;
        }

        /* stylelint-disable */
        &:focus-within {

            & > {
                #{$form}__label {
                    color: map-deep-get($colors, "brand", "blue");
                }
            }


        }
        /* stylelint-enable */
    }

    &__invalid-message {
        color: map-deep-get($colors, "validation", "invalid");
        display: none;

        // Eigen v-cloak variant omdat de webkey formulieren niet te bewerken zijn
        /* stylelint-disable */
        &[show] {
            display: block;
        }
        /* stylelint-enable */
    }

    &__label {
        font-weight: 900;
        font-size: rem-calc(14);
        text-transform: uppercase;
        display: block;
        margin-bottom: 0.25em;
        letter-spacing: 0.2em;
    }

    &--loading {
        opacity: .5;
    }

    &__file-input-wrapper {
        position: relative;

        // html tag aanroepen omdat we de html niet kunnen beinvloeden door formbuilder
        /* stylelint-disable */
        span {
            @extend .button;
            @extend .button--primary;

            &::before {
                content: "Upload een bestand";
            }
        }

        &--has-file span {
            @extend .button--white;

            &::before {
                content: none;
            }
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            cursor: pointer;
        }
        /* stylelint-enable */
    }
}
