.projects-slider {
    margin-right: -#{($grid-gutter-width / 2) + 10};

    @include media-breakpoint-up(lg) {
        margin-right: 0;
    }

    &__slide {
        margin-right: 5%;
        width: 85%;

        @include media-breakpoint-up(lg) {
            width: 45%;
            margin-left: 5.05%;
        }
    }

    &__title {
        margin-top: 0;
    }

    &__nav {
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 50px;
    }

    &__nav-item {
        background: none;
        border: none;
        height: 12px;
        padding: 0;
        margin: 0 10px;
        width: 20px;
        line-height: 0;

        &:hover,
        &:focus,
        &:active {
            outline: none;
        }

        svg {
            fill: map-deep-get($colors, "black", "default");
            transition: $transition-default;
            height: 12px;
            vertical-align: unset;
            width: 20px;
        }

        &:hover {
            svg {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }
    }
}
