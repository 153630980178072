// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    -webkit-font-smoothing: antialiased;
    line-height: 1.66em;

    @include media-breakpoint-up(md) {
        &--centered {
            text-align: center;
        }
    }

    &--red-headers {
        h1, h2, h3, h4, h5, h6 {
            color: map-deep-get($colors, "brand", "primary");
        }
    }

    .intro,
    .lead {
        font-size: rem-calc(24);
        line-height: 1.7em;
        margin-bottom: 1.5em;
    }

    img {
        max-width: 100%;
    }

    a:not(.button) {
        text-decoration: underline;
    }

    ul {
        margin: rem-calc(20) 0;

        li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 0;

            &::before {
                content: "";
                background: map-deep-get($colors, "brand", "primary");
                width: 0.35em;
                height: .35em;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: .65em;
            }
        }

        ul {
            margin: 0;
        }
    }
}

/* stylelint-enable */