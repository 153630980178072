.project-card {
    width: 100%;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
        margin-bottom: 40px;
    }

    $elm: &;

    &__image {
        width: 100%;
        height: auto;
    }

    &__content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        padding: 25px 30px;
        background: linear-gradient(180deg, rgba(map-deep-get($colors, "black", "default"), 0) 37.55%, map-deep-get($colors, "black", "default") 100%);
        display: flex;
        align-content: flex-end;
        flex-wrap: wrap;
        transition: $transition-default;
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
        text-transform: uppercase;
        transition: $transition-default;
        flex: 1 1 100%;
    }

    &__link {
        margin-top: 0.2em;
        flex: 1 1 100%;
        color: map-deep-get($colors, "brand", "primary");
    }

    &:hover {
        text-decoration: none;

        #{$elm}__title {
            margin-bottom: 1em;
        }

        #{$elm}__content {
            padding-bottom: 35px;
        }
    }
}