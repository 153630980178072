﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

p {
    margin-top: 0;
}

h1, .h1 {
    font-weight: 800;
    font-size: 8vw;
    line-height: 1em;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(45);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(60);
    }
}

h2, .h2 {
    font-size: 7vw;
    font-weight: 800;
    line-height: 1.2em;
    margin-bottom: 0.5em;
    margin-top: .5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(38);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(44);
    }
}

h3, .h3 {
    font-size: 5.5vw;
    font-weight: 800;
    line-height: 1.2em;
    margin-bottom: 0.5em;
    margin-top: .5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(22);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(28);
    }
}

h4, .h4 {
    font-size: rem-calc(20);
    font-weight: 800;
    line-height: 1.15em;
    margin-bottom: 0.5em;
    margin-top: .5em;
}

h5, .h5 {
    font-size: rem-calc(18);
    font-weight: 900;
    line-height: 1.16em;
    margin-bottom: 0.5em;
    margin-top: .5em;
    text-transform: uppercase;
}

h6, .h6 {
    font-size: rem-calc(14);
    font-weight: 800;
    line-height: 1.2em;
    margin-bottom: 0.5em;
    margin-top: .5em;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

strong {
    font-weight: 900;
}

.intro,
.lead {
    font-size: rem-calc(24);
    line-height: 1.25em;
}