.block-link {
    display: inline-block;
    margin: 5px;
    width: 100%;

    @include media-breakpoint-up(md) {
        width: auto;
    }

    $elm: &;

    &__link {
        background: map-deep-get($colors, "gray", "light");
        color: map-deep-get($colors, "black", "default");
        border-radius: 8px;
        display: flex;
        padding: 15px 20px;
        transition: $transition-default;

        &:hover {
            text-decoration: none;
            background: darken(map-deep-get($colors, "gray", "light"), 10%);

            #{$elm}__icon:not(#{$elm}__icon--dot) {
                margin: 0 0.05em 0 0.3em;
            }
        }

        &--red {
            background: map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "white", "default");

            &:hover {
                background: darken(map-deep-get($colors, "brand", "primary"), 5%);
                color: map-deep-get($colors, "white", "default");
            }

            #{$elm}__icon svg {
                fill: map-deep-get($colors, "brand", "secondary");
            }
        }

        &--caps {
            text-transform: uppercase;
            letter-spacing: 0.2em;
            font-size: rem-calc(14);
        }
    }

    &__icon {
        width: rem-calc(20);
        height: rem-calc(12);
        display: inline-block;
        margin: 0 0.35em 0 0;
        transition: $transition-default;
        flex: 0 0 auto;

        svg {
            width: 100%;
            height: rem-calc(12);
            fill: map-deep-get($colors, "brand", "primary");
        }

        &--dot {
            width: 0.85em;
            height: 0.85em;
            border-radius: 50%;
            background: map-deep-get($colors, "gray", "dark");
            vertical-align: middle;
            align-self: center;
        }
    }

    &--active #{$elm}__icon--dot {
        background: map-deep-get($colors, "brand", "secondary");
    }

    &__text {
        color: inherit;
    }
}