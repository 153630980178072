.pagination {
    display: flex;
    justify-content: flex-end;

    &__list {
        margin: 0;
    }

    &__list-item {
        display: inline-block;
        padding-left: 12px;
    }

    &__control-link,
    &__list-link {
        @extend .button;
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "black", "default");
        border: 1px solid map-deep-get($colors, "brand", "primary");
        padding: 0.865em 1.05em;
        line-height: 1em;
        min-height: 0;

        &:hover {
            text-decoration: none;
            border: 1px solid map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "white", "default");
            background: map-deep-get($colors, "brand", "primary");
        }

        &--active {
            background: map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "brand", "secondary");
        }

        &--control {
            background: map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "white", "default");

            &:hover {
                background-color: darken(saturate(map-deep-get($colors, "brand", "secondary"), 11.52), 12.35);
                color: map-deep-get($colors, "black", "default");
            }
        }
    }

    &__control-link {
        &--inactive {
            display: none;
        }
    }
}