.navigation {
    $elm: &;
    padding: 20px 0;
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        padding: 0;
        height: auto;
        display: inline-block;
        flex-wrap: unset;
        width: auto;
    }

    &--active {
        #{$elm}__list-item {
            opacity: 1;
            padding: 5px 0;
        }
    }

    &__wrapper {
        height: 0;
        overflow: hidden;
        display: flex;

        @include media-breakpoint-up(md) {
            height: auto;
            overflow: visible;
        }

        &--active {
            transition: all ease-in-out 0s 0s;
            margin-top: -#{map-get($header-height, "small")};
            padding-top: #{map-get($header-height, "small")};
            height: 100%;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            padding-top: 0;
        }
    }

    &__list {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    &__list-item {
        transition: all $transition-default-time #{$transition-default-time / 2} $transition-default-smoothing;
        opacity: 0;
        display: block;
        position: relative;
        padding: 10px 0;

        @include media-breakpoint-up(md) {
            padding: 0;
            margin: 0 8px;
            opacity: 1;
            display: inline-block;

            @include media-breakpoint-up(xxl) {
                margin: 0 10px;
            }
        }

        &--last {
            margin-right: 0;
            $e: &;

            &:hover::before{
                margin-top: 0 !important;
            }

            @at-root .touch-device #{$e}.click::before {
                margin-top: 0 !important;
            }

            #{$elm}__list--sub {
                margin-top: 8px;
            }
        }
    }

    @at-root .click-device #{$elm}__list-item--has-sub:hover {
        background: darken(map-deep-get($colors, "brand", "primary"), 12%);
        border-radius: 8px;

        @include media-breakpoint-up(md) {
            background: none;

            &::before {
                content: "";
                border-left: 8px solid transparent;
                border-bottom: 8px solid map-deep-get($colors, "brand", "primary");
                border-right: 8px solid transparent;
                position: absolute;
                left: 50%;
                margin-left: -6px;
                margin-top: 12px;
                top: 100%;
                transition: $transition-default;
            }
        }

        #{$elm}__list--sub {
            display: block;
        }

        #{$elm}__list--sub::before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 100%;
            height: 35px;
            width: 75%;
        }

        #{$elm}__list--sub .navigation__item {
            color: map-deep-get($colors, "white", "default");
        }
    }

    @at-root .touch-device #{$elm}__list-item--has-sub.click {
        background: darken(map-deep-get($colors, "brand", "primary"), 12%);
        border-radius: 8px;

        @include media-breakpoint-up(md) {
            background: none;

            &::before {
                content: "";
                border-left: 8px solid transparent;
                border-bottom: 8px solid map-deep-get($colors, "brand", "primary");
                border-right: 8px solid transparent;
                position: absolute;
                left: 50%;
                margin-left: -6px;
                margin-top: 12px;
                top: 100%;
                transition: $transition-default;
            }
        }

        #{$elm}__list--sub {
            display: block;
        }

        #{$elm}__list--sub::before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 100%;
            height: 35px;
            width: 75%;
        }

        #{$elm}__list--sub .navigation__item {
            color: map-deep-get($colors, "white", "default");
        }
    }

    @at-root .click-device #{$elm}__list-item:hover #{$elm}__item {
        text-decoration: none;
        color: map-deep-get($colors, "white", "default");

        @include media-breakpoint-up(md) {
            color: map-deep-get($colors, "brand", "secondary");
        }

        svg {
            transform: rotateX(180deg);
        }

        #{$elm}__item-icon svg {
            transform: none;
        }
    }

    @at-root .touch-device #{$elm}__list-item.click #{$elm}__item {
        text-decoration: none;
        color: map-deep-get($colors, "white", "default");

        @include media-breakpoint-up(md) {
            color: map-deep-get($colors, "brand", "secondary");
        }

        svg {
            transform: rotateX(180deg);
        }

        #{$elm}__item-icon svg {
            transform: none;
        }
    }

    &__item {
        display: inline-flex;
        font-size: rem-calc(28);
        font-weight: 800;
        line-height: 1.25em;
        color: map-deep-get($colors, "white", "default");
        padding: 5px 20px;
        margin-bottom: 5px;
        white-space: nowrap;
        transition: $transition-default;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;

        @include media-breakpoint-up(md) {
            padding: 5px 0;
            font-size: rem-calc(10);
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(12);
        }

        @include media-breakpoint-up(xl) {
            font-size: rem-calc(14);
        }

        svg {
            display: inline-block;
            width: 1.2em;
            height: 1.2em;
            margin-left: 0.75em;
            fill: map-deep-get($colors, "white", "default");
            transition: $transition-default;
        }

        &--active {
            color: map-deep-get($colors, "brand", "secondary");
        }

        &--highlight {
            color: map-deep-get($colors, "white", "default");
            background: map-deep-get($colors, "brand", "primary");
            display: inline-block;

            @include media-breakpoint-up(md) {
                padding: 20px 15px 20px 10px;
            }

            @include media-breakpoint-up(lg) {
                padding: 25px 20px 24.2px 15px;
            }

            @include media-breakpoint-up(xl) {
                padding: 32px 50px 29.6px 40px;
            }
        }
    }

    &__list--sub {
        display: none;
        padding: 20px 35px;
        border-radius: 8px;
        transition: $transition-default;

        #{$elm}__list-item {
            display: block;
            width: 100%;

            &--address-start {
                margin-top: 0.5em;
            }

            &--address-end {
                margin-bottom: 0.5em;
            }
        }

        #{$elm}__item-text {
            transition: $transition-default;
            border-bottom: 1px solid rgba(map-deep-get($colors, "white", "default"), 0.5);
        }

        #{$elm}__item {
            font-weight: 600;
            font-size: rem-calc(14);
            letter-spacing: 0.2em;
            margin: 0;
            margin-bottom: 0.5em;
            line-height: 1;

            @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
                &:hover {
                    color: map-deep-get($colors, "white", "default");
                    text-decoration: none;
                }

                &:hover #{$elm}__item-text {
                    border-bottom: 1px solid rgba(map-deep-get($colors, "white", "default"), 1);
                }
            }
        }

        #{$elm}__item-text--static {
            color: map-deep-get($colors, "brand", "secondary");
            border-bottom: none;

            @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        #{$elm}__item-icon {
            width: 29px;
            height: 29px;
            display: inline-block;
            vertical-align: middle;
            margin: -7px 1.25em -7px -15px;

            svg {
                fill: map-deep-get($colors, "brand", "secondary");
                width: 100%;
                height: 100%;
            }
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 100%;
            right: 0;
            margin-top: 20px;
            background: map-deep-get($colors, "brand", "primary");
            box-shadow: 0 22px 34px rgba(map-deep-get($colors, "black", "default"), 0.479);
            color: map-deep-get($colors, "white", "default");
        }
    }

    &__list--address {
        display: block;
        padding-left: 20px;
        padding-right: 20px;

        #{$elm}__item {
            padding-left: 0;
        }

        @include media-breakpoint-up(md) {
            display: none;
            padding: 20px 35px;
        }
    }

    @at-root .click-device .header--scrolled & {
        &__list-item--has-sub:hover::before {
            margin-top: 27px; //35 van de margin-top bij het submenu - 8 van het pijljte
        }
    }

    @at-root .touch-device .header--scrolled & {
        &__list-item--has-sub.click::before {
            margin-top: 27px; //35 van de margin-top bij het submenu - 8 van het pijljte
        }
    }

    @at-root body .header--scrolled & {
        &__list--sub {
            @include media-breakpoint-up(md) {
                margin-top: 35px;
            }
        }

        @include media-breakpoint-up(md) {
            &__list-item--last {
                #{$elm}__list--sub {
                    margin-top: 8px;
                }
            }
        }
    }

    &__badge {
        background: map-deep-get($colors, "brand", "primary");
        border-radius: 50%;
        display: none;
        font-size: 12px;
        height: 1.25em;
        line-height: 1.25em;
        margin-top: -0.65em;
        min-width: 1.25em;
        padding: 0 0.125em;
        text-align: center;

        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }
}
