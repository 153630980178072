.home-slider-content {
    box-shadow: 0 24px 44px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 8px 8px;
    margin-left: -#{($grid-gutter-width / 2) + 10};
    margin-right: -#{($grid-gutter-width / 2) + 10};

    @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
    }

    /* stylelint-disable */
    .flickity-slider {
        transform: none !important;
    }

    &__slide {
        left: 0 !important;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        z-index: -1;
        width: 100%;
        padding: #{$grid-gutter-width / 2} #{$grid-gutter-width};

        @include media-breakpoint-up(md) {
            padding: #{$grid-gutter-width / 2} 40px 40px;
        }

        @include media-breakpoint-up(xl) {
            padding: 0 40px 40px;
        }

        h1, h2, h3, h4, h5, h6 {
            color: map-deep-get($colors, "brand", "primary");
        }

        &.is-selected {
            opacity: 1;
            z-index: 0
        }
    }
    /* stylelint-enable */
}