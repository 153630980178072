.project-filters {
    $elm: &;

    &__item {
        display: none;
        transition: $transition-default;

        @include media-breakpoint-up(lg) {
            display: inline-block;
        }
    }

    &--active {
        #{$elm}__item {
            display: block;
        }
    }

    &__icon {
        transition: $transition-default;
        display: inline-block;
        transform: rotateX(0deg);
        height: 1em;
        width: 1.2em;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        svg {
            display: inline-block;
            width: 1.2em;
            height: 1em;
            fill: map-deep-get($colors, "white", "default");
        }

        &--active {
            transform: rotateX(180deg);
        }
    }
}