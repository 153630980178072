.home-slide {
    height: 70vh;
    position: relative;
    width: 100%;

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(map-deep-get($colors, "black", "default"), 0.6);
        }
    }

    &__photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content {
        height: 100%;
        display: flex;
        flex: 1 1 100%;
        align-content: center;
        align-items: center;
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
        font-size: rem-calc(50);
    }

    &__subtitle {
        color: map-deep-get($colors, "white", "default");
        font-size: rem-calc(15);
    }
}