@import url("https://fonts.googleapis.com/css?family=Mukta:400,500,600,700,800");
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Mukta", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #c40f16;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #7d0a0e;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 992px) {
    .container {
      max-width: 1200px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1340px; } }

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-13, .col-xxl-14, .col-xxl-15, .col-xxl-16, .col-xxl-17, .col-xxl-18, .col-xxl-19, .col-xxl-20, .col-xxl-21, .col-xxl-22, .col-xxl-23, .col-xxl-24, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%; }

.col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-4 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-5 {
  flex: 0 0 20.83333%;
  max-width: 20.83333%; }

.col-6 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-7 {
  flex: 0 0 29.16667%;
  max-width: 29.16667%; }

.col-8 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-10 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-11 {
  flex: 0 0 45.83333%;
  max-width: 45.83333%; }

.col-12 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-13 {
  flex: 0 0 54.16667%;
  max-width: 54.16667%; }

.col-14 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-16 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-17 {
  flex: 0 0 70.83333%;
  max-width: 70.83333%; }

.col-18 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-19 {
  flex: 0 0 79.16667%;
  max-width: 79.16667%; }

.col-20 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-22 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-23 {
  flex: 0 0 95.83333%;
  max-width: 95.83333%; }

.col-24 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 25; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

.offset-1 {
  margin-left: 4.16667%; }

.offset-2 {
  margin-left: 8.33333%; }

.offset-3 {
  margin-left: 12.5%; }

.offset-4 {
  margin-left: 16.66667%; }

.offset-5 {
  margin-left: 20.83333%; }

.offset-6 {
  margin-left: 25%; }

.offset-7 {
  margin-left: 29.16667%; }

.offset-8 {
  margin-left: 33.33333%; }

.offset-9 {
  margin-left: 37.5%; }

.offset-10 {
  margin-left: 41.66667%; }

.offset-11 {
  margin-left: 45.83333%; }

.offset-12 {
  margin-left: 50%; }

.offset-13 {
  margin-left: 54.16667%; }

.offset-14 {
  margin-left: 58.33333%; }

.offset-15 {
  margin-left: 62.5%; }

.offset-16 {
  margin-left: 66.66667%; }

.offset-17 {
  margin-left: 70.83333%; }

.offset-18 {
  margin-left: 75%; }

.offset-19 {
  margin-left: 79.16667%; }

.offset-20 {
  margin-left: 83.33333%; }

.offset-21 {
  margin-left: 87.5%; }

.offset-22 {
  margin-left: 91.66667%; }

.offset-23 {
  margin-left: 95.83333%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 25; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.16667%; }
  .offset-sm-2 {
    margin-left: 8.33333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.66667%; }
  .offset-sm-5 {
    margin-left: 20.83333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.16667%; }
  .offset-sm-8 {
    margin-left: 33.33333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.66667%; }
  .offset-sm-11 {
    margin-left: 45.83333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.16667%; }
  .offset-sm-14 {
    margin-left: 58.33333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.66667%; }
  .offset-sm-17 {
    margin-left: 70.83333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.16667%; }
  .offset-sm-20 {
    margin-left: 83.33333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.66667%; }
  .offset-sm-23 {
    margin-left: 95.83333%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 25; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.16667%; }
  .offset-md-2 {
    margin-left: 8.33333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.66667%; }
  .offset-md-5 {
    margin-left: 20.83333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.16667%; }
  .offset-md-8 {
    margin-left: 33.33333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.66667%; }
  .offset-md-11 {
    margin-left: 45.83333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.16667%; }
  .offset-md-14 {
    margin-left: 58.33333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.66667%; }
  .offset-md-17 {
    margin-left: 70.83333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.16667%; }
  .offset-md-20 {
    margin-left: 83.33333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.66667%; }
  .offset-md-23 {
    margin-left: 95.83333%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 25; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.16667%; }
  .offset-lg-2 {
    margin-left: 8.33333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.66667%; }
  .offset-lg-5 {
    margin-left: 20.83333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.16667%; }
  .offset-lg-8 {
    margin-left: 33.33333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.66667%; }
  .offset-lg-11 {
    margin-left: 45.83333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.16667%; }
  .offset-lg-14 {
    margin-left: 58.33333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.66667%; }
  .offset-lg-17 {
    margin-left: 70.83333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.16667%; }
  .offset-lg-20 {
    margin-left: 83.33333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.66667%; }
  .offset-lg-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 25; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xxl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xxl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xxl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xxl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xxl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xxl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xxl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xxl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xxl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xxl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xxl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xxl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 25; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .order-xxl-13 {
    order: 13; }
  .order-xxl-14 {
    order: 14; }
  .order-xxl-15 {
    order: 15; }
  .order-xxl-16 {
    order: 16; }
  .order-xxl-17 {
    order: 17; }
  .order-xxl-18 {
    order: 18; }
  .order-xxl-19 {
    order: 19; }
  .order-xxl-20 {
    order: 20; }
  .order-xxl-21 {
    order: 21; }
  .order-xxl-22 {
    order: 22; }
  .order-xxl-23 {
    order: 23; }
  .order-xxl-24 {
    order: 24; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 4.16667%; }
  .offset-xxl-2 {
    margin-left: 8.33333%; }
  .offset-xxl-3 {
    margin-left: 12.5%; }
  .offset-xxl-4 {
    margin-left: 16.66667%; }
  .offset-xxl-5 {
    margin-left: 20.83333%; }
  .offset-xxl-6 {
    margin-left: 25%; }
  .offset-xxl-7 {
    margin-left: 29.16667%; }
  .offset-xxl-8 {
    margin-left: 33.33333%; }
  .offset-xxl-9 {
    margin-left: 37.5%; }
  .offset-xxl-10 {
    margin-left: 41.66667%; }
  .offset-xxl-11 {
    margin-left: 45.83333%; }
  .offset-xxl-12 {
    margin-left: 50%; }
  .offset-xxl-13 {
    margin-left: 54.16667%; }
  .offset-xxl-14 {
    margin-left: 58.33333%; }
  .offset-xxl-15 {
    margin-left: 62.5%; }
  .offset-xxl-16 {
    margin-left: 66.66667%; }
  .offset-xxl-17 {
    margin-left: 70.83333%; }
  .offset-xxl-18 {
    margin-left: 75%; }
  .offset-xxl-19 {
    margin-left: 79.16667%; }
  .offset-xxl-20 {
    margin-left: 83.33333%; }
  .offset-xxl-21 {
    margin-left: 87.5%; }
  .offset-xxl-22 {
    margin-left: 91.66667%; }
  .offset-xxl-23 {
    margin-left: 95.83333%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 5px 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 1; }

.hamburger-box {
  width: 24px;
  height: 14px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 24px;
    height: 2px;
    background-color: #ffffff;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -6px; }
  .hamburger-inner::after {
    bottom: -6px; }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger {
  z-index: 11;
  color: #ffffff;
  display: flex;
  align-items: center; }
  .hamburger--active {
    color: #000000; }
    .hamburger--active .hamburger__label {
      margin-right: 1em; }
  .hamburger__label {
    font-size: 0.875rem;
    font-weight: 800;
    letter-spacing: 0.2em;
    line-height: 0.8em;
    margin-right: 0.5em;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase; }

.b-lazy {
  opacity: 0;
  transition: all 0.3s ease-in-out; }
  .b-lazy.b-loaded {
    opacity: 1; }

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: 0; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: #fff;
  cursor: pointer; }

.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f; }

.flickity-button:active {
  opacity: .6; }

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none; }

.flickity-button-icon {
  fill: #333; }

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: .25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.flickity-viewport {
  transition: height .3s ease-in-out; }

*,
*::before,
*::after {
  box-sizing: border-box; }

*:focus {
  outline: none; }

html, body {
  margin: 0 auto;
  padding: 0;
  font-size: 1rem;
  font-family: "Mukta", sans-serif; }
  @media (min-width: 768px) {
    html, body {
      font-size: 1.125rem; } }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

a {
  text-decoration: none; }

b,
strong {
  font-weight: 700; }

::selection {
  background-color: #c40f16;
  color: #ffffff; }

.h-100 {
  height: 100%; }

@media (min-width: 768px) {
  .h-md-auto {
    height: auto; } }

[v-cloak] {
  display: none; }

/* stylelint-disable */
.touch-device .d-touch-none {
  display: none !important; }

.click-device .d-click-none {
  display: none !important; }

body:not(.body--loaded) .d-loading-none {
  display: none !important; }

/* stylelint-enable */
.button, .form__file-input-wrapper span, .pagination__control-link, .pagination__list-link {
  padding: 0.75em 1.25em;
  min-height: 3.125rem;
  line-height: 1.64em;
  font-size: 0.875rem;
  border-radius: 8px;
  background-color: #616161;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  letter-spacing: 0.2em;
  border: none; }
  @media (pointer: fine), (min-width: 1200px) {
    .button:hover, .form__file-input-wrapper span:hover, .pagination__control-link:hover, .pagination__list-link:hover {
      text-decoration: none; } }
  .button svg, .form__file-input-wrapper span svg, .pagination__control-link svg, .pagination__list-link svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
    vertical-align: middle; }
  .button--wide {
    width: 100%;
    justify-content: center; }
  .button--primary, .form__button, .form__file-input-wrapper span {
    background-color: #c40f16;
    color: #ffffff; }
    .button--primary svg, .form__button svg, .form__file-input-wrapper span svg {
      fill: #fbd41a; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--primary:hover, .form__button:hover, .form__file-input-wrapper span:hover {
        color: #ffffff;
        background-color: #871116; } }
  .button--secondary {
    background-color: #fbd41a;
    color: #000000; }
    .button--secondary svg {
      fill: #c40f16; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--secondary:hover {
        background-color: #d6b100;
        color: #000000; } }
  .button--blue {
    background-color: #1d8ebf;
    color: #000000; }
    .button--blue svg {
      fill: #c40f16; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--blue:hover {
        background-color: #0c6991;
        color: #000000; } }
  .button--black {
    background: #000000; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--black:hover {
        background-color: #1a1a1a; } }
  .button--white, .form__file-input-wrapper--has-file span {
    background: #ffffff;
    color: #c40f16; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--white:hover, .form__file-input-wrapper--has-file span:hover {
        background: #e3e3e3; } }
  .button--mb {
    margin-bottom: 20px; }
  .button:disabled, .form__file-input-wrapper span:disabled, .pagination__control-link:disabled, .pagination__list-link:disabled, .button--disabled {
    background: #616161;
    color: #000000;
    cursor: not-allowed; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

p {
  margin-top: 0; }

h1, .h1 {
  font-weight: 800;
  font-size: 8vw;
  line-height: 1em;
  margin-bottom: 0.5em; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 2.8125rem; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 3.75rem; } }

h2, .h2 {
  font-size: 7vw;
  font-weight: 800;
  line-height: 1.2em;
  margin-bottom: 0.5em;
  margin-top: .5em; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 2.375rem; } }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2.75rem; } }

h3, .h3 {
  font-size: 5.5vw;
  font-weight: 800;
  line-height: 1.2em;
  margin-bottom: 0.5em;
  margin-top: .5em; }
  @media (min-width: 768px) {
    h3, .h3 {
      font-size: 1.375rem; } }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.15em;
  margin-bottom: 0.5em;
  margin-top: .5em; }

h5, .h5 {
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1.16em;
  margin-bottom: 0.5em;
  margin-top: .5em;
  text-transform: uppercase; }

h6, .h6 {
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1.2em;
  margin-bottom: 0.5em;
  margin-top: .5em;
  text-transform: uppercase;
  letter-spacing: 0.2em; }

strong {
  font-weight: 900; }

.intro,
.lead {
  font-size: 1.5rem;
  line-height: 1.25em; }

/* stylelint-disable */
.rich-text-area {
  -webkit-font-smoothing: antialiased;
  line-height: 1.66em; }
  @media (min-width: 768px) {
    .rich-text-area--centered {
      text-align: center; } }
  .rich-text-area--red-headers h1, .rich-text-area--red-headers h2, .rich-text-area--red-headers h3, .rich-text-area--red-headers h4, .rich-text-area--red-headers h5, .rich-text-area--red-headers h6 {
    color: #c40f16; }
  .rich-text-area .intro,
  .rich-text-area .lead {
    font-size: 1.5rem;
    line-height: 1.7em;
    margin-bottom: 1.5em; }
  .rich-text-area img {
    max-width: 100%; }
  .rich-text-area a:not(.button):not(.pagination__control-link):not(.pagination__list-link) {
    text-decoration: underline; }
  .rich-text-area ul {
    margin: 1.25rem 0; }
    .rich-text-area ul li {
      position: relative;
      padding-left: 20px;
      margin-bottom: 0; }
      .rich-text-area ul li::before {
        content: "";
        background: #c40f16;
        width: 0.35em;
        height: .35em;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: .65em; }
    .rich-text-area ul ul {
      margin: 0; }

/* stylelint-enable */
.barba-bg {
  position: fixed;
  z-index: 110;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #ffffff;
  margin-left: 100%;
  opacity: 0;
  transition: margin 0s linear .3s, opacity .3s ease-in-out; }
  .barba-bg--visible {
    opacity: 1;
    margin-left: 0;
    transition: margin 0s linear, opacity .3s ease-in-out; }

.barba-wrapper {
  height: 100%; }

.barba-container {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%; }

input, select {
  background: #ffffff;
  border: 2px solid transparent;
  border-radius: 8px;
  color: #000000;
  font-family: "Mukta", sans-serif;
  height: 3.125rem;
  padding: 0 1rem;
  transition: all 0.3s ease-in-out;
  width: 100%; }
  input:focus, select:focus {
    outline: none;
    border: 2px solid #1d8ebf; }

textarea {
  background: #ffffff;
  border: 2px solid transparent;
  border-radius: 8px;
  color: #000000;
  font-family: "Mukta", sans-serif;
  min-height: 9.375rem;
  padding: 8px 16px;
  resize: vertical;
  transition: border .3s ease-in-out;
  width: 100%; }
  textarea:focus {
    border: 2px solid #1d8ebf;
    outline: none; }

select {
  appearance: none;
  background: #ffffff;
  color: #000000;
  border-radius: 8px; }
  select::-ms-expand {
    display: none; }

.select-wrapper {
  position: relative;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .select-wrapper__select {
    height: 3.125rem;
    color: #000000;
    border: 2px solid transparent;
    font-weight: 500;
    padding: 0 20px; }
    .select-wrapper__select:focus {
      border: 2px solid #1d8ebf; }
  .select-wrapper__dropdown-icon {
    position: absolute;
    right: 0.625rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none; }
  .select-wrapper option {
    font-size: 18px;
    padding: 12px 0; }

.radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .radio-wrapper__input-wrapper {
    position: relative;
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 0.5rem;
    float: left;
    cursor: pointer;
    flex-shrink: 0; }
    .radio-wrapper__input-wrapper::before {
      content: "";
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid #000000;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; }
  .radio-wrapper__input-dot {
    position: absolute;
    left: 0.3125rem;
    top: 0.3125rem;
    z-index: 2;
    border-radius: 50%;
    background-color: #c40f16;
    width: 0.9375rem;
    height: 0.9375rem;
    opacity: 0; }
  .radio-wrapper__input, .radio-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .radio-wrapper__input:checked, .radio-wrapper input:checked {
      border: 1px solid #c40f16; }
      .radio-wrapper__input:checked + .radio-wrapper__input-dot, .radio-wrapper input:checked + .radio-wrapper__input-dot {
        opacity: 1; }
  .radio-wrapper__label, .radio-wrapper label {
    font-size: 0.9375rem;
    cursor: pointer;
    font-weight: 400; }

.checkbox-wrapper {
  display: flex;
  align-items: start;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .checkbox-wrapper__input-wrapper {
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    line-height: 1.75rem; }
  .checkbox-wrapper__input-dot {
    width: 1.875rem;
    height: 1.875rem;
    background-color: #ffffff;
    border: 1px solid #c40f16;
    margin-right: 1.25rem;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .checkbox-wrapper__input-dot svg {
      opacity: 0;
      fill: #ffffff;
      width: 70%;
      height: 70%; }
  .checkbox-wrapper__input, .checkbox-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .checkbox-wrapper__input:checked + .checkbox-wrapper__input-dot, .checkbox-wrapper input:checked + .checkbox-wrapper__input-dot {
      background-color: #c40f16; }
      .checkbox-wrapper__input:checked + .checkbox-wrapper__input-dot svg, .checkbox-wrapper input:checked + .checkbox-wrapper__input-dot svg {
        opacity: 1; }
  .checkbox-wrapper__label, .checkbox-wrapper label {
    cursor: pointer;
    align-self: center;
    font-weight: 400;
    text-transform: none;
    font-size: 1.125rem;
    letter-spacing: normal; }

.checkbox-wrapper:focus-within label.form__label,
.radio-wrapper:focus-within label.form__label {
  color: #000000; }

.form-message {
  background-color: #616161;
  border-radius: 4px;
  color: #ffffff;
  padding: 12px 46px 12px 12px;
  margin: 2rem 0;
  position: relative;
  align-items: center;
  display: none;
  min-height: 3rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .form-message a {
    color: #ffffff;
    text-decoration: underline; }
  .form-message--active {
    display: flex; }
  .form-message--success {
    background-color: #55b890; }
    .form-message--success .form-message__icon--success {
      display: flex; }
  .form-message--error {
    background-color: #e50044; }
    .form-message--error .form-message__icon--error {
      display: flex; }
  .form-message__icon {
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: none;
    border: 1px solid #ffffff; }
    .form-message__icon svg {
      width: 1rem;
      fill: #ffffff; }

.form {
  overflow: hidden;
  transition: all 0.3s ease-in-out; }
  .form__group-wrapper--half .form__group:last-of-type {
    margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .form__group-wrapper--half {
      display: flex;
      margin: 0 -0.5rem; }
      .form__group-wrapper--half .form__group {
        margin: 0 0.5rem 1.5rem;
        width: 50%; } }
  .form__group {
    margin-bottom: 1.5rem;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .form__group:last-of-type {
      margin-bottom: 0; }
    .form__group:focus-within > .form__label {
      color: #1d8ebf; }
  .form__invalid-message {
    color: #e50044;
    display: none;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .form__invalid-message[show] {
      display: block; }
  .form__label {
    font-weight: 900;
    font-size: 0.875rem;
    text-transform: uppercase;
    display: block;
    margin-bottom: 0.25em;
    letter-spacing: 0.2em; }
  .form--loading {
    opacity: .5; }
  .form__file-input-wrapper {
    position: relative;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .form__file-input-wrapper span::before {
      content: "Upload een bestand"; }
    .form__file-input-wrapper--has-file span::before {
      content: none; }
    .form__file-input-wrapper input {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer; }

/* stylelint-disable */
.cc-btn {
  flex: none !important; }

.cc-revoke {
  display: none !important; }

/* stylelint-enable */
/* stylelint-disable */
.grecaptcha-badge {
  display: none !important; }

/* stylelint-enable */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  transition: all 0.3s ease-in-out; }
  .header__wrapper {
    overflow: hidden; }
    @media (min-width: 768px) {
      .header__wrapper {
        transition: all 0.3s ease-in-out;
        background-color: transparent;
        height: auto;
        overflow: visible; } }
  .header--scrolled:not(.header--expanded) {
    background: #616161; }
    @media (min-width: 768px) {
      .header--scrolled:not(.header--expanded) {
        background: none; } }
  @media (min-width: 768px) {
    .header--scrolled .header__wrapper {
      background: #616161; } }
  .header--scrolled .header__start svg {
    fill: #616161; }
  .header--expanded {
    background: #c40f16;
    height: 100%;
    overflow: hidden;
    overflow-y: auto; }
  .header__logo {
    transition: all 0.3s ease-in-out;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-flex;
    position: relative;
    align-items: center;
    align-content: center; }
    @media (min-width: 768px) {
      .header__logo {
        padding-top: 0;
        padding-bottom: 0; } }
  .header__logo-image {
    height: 37.8px;
    width: 175px; }
    @media (min-width: 768px) {
      .header__logo-image {
        height: 40px;
        width: 40px; } }
    @media (min-width: 992px) {
      .header__logo-image {
        height: 43.2px;
        width: 200px; } }
    @media (min-width: 1200px) {
      .header__logo-image {
        height: 47.52px;
        width: 220px; } }
    @media (min-width: 1600px) {
      .header__logo-image {
        height: 54px;
        width: 250px; } }
  .header__start, .header__end {
    display: inline-block; }
    .header__start svg, .header__end svg {
      fill: transparent;
      transition: all 0.3s ease-in-out; }
      @media (min-width: 768px) {
        .header__start svg, .header__end svg {
          height: 52px;
          width: 30px; } }
      @media (min-width: 992px) {
        .header__start svg, .header__end svg {
          height: 65px;
          width: 38px; } }
      @media (min-width: 1200px) {
        .header__start svg, .header__end svg {
          height: 81px;
          width: 46px; } }
  .header__start {
    margin-right: -1px; }
  .header__end {
    margin-left: -1px; }
    .header__end svg {
      fill: #c40f16; }
  .header__menu-toggle {
    float: right;
    position: relative; }

.navigation {
  padding: 20px 0;
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .navigation {
      padding: 0;
      height: auto;
      display: inline-block;
      flex-wrap: unset;
      width: auto; } }
  .navigation--active .navigation__list-item {
    opacity: 1;
    padding: 5px 0; }
  .navigation__wrapper {
    height: 0;
    overflow: hidden;
    display: flex; }
    @media (min-width: 768px) {
      .navigation__wrapper {
        height: auto;
        overflow: visible; } }
    .navigation__wrapper--active {
      transition: all ease-in-out 0s 0s;
      margin-top: -50px;
      padding-top: 50px;
      height: 100%; }
    @media (min-width: 768px) {
      .navigation__wrapper {
        margin-top: 0;
        padding-top: 0; } }
  .navigation__list {
    width: 100%; }
    @media (min-width: 768px) {
      .navigation__list {
        width: auto; } }
  .navigation__list-item {
    transition: all 0.3s 0.15s ease-in-out;
    opacity: 0;
    display: block;
    position: relative;
    padding: 10px 0; }
    @media (min-width: 768px) {
      .navigation__list-item {
        padding: 0;
        margin: 0 8px;
        opacity: 1;
        display: inline-block; } }
  @media (min-width: 768px) and (min-width: 1600px) {
    .navigation__list-item {
      margin: 0 10px; } }
    .navigation__list-item--last {
      margin-right: 0; }
      .navigation__list-item--last:hover::before {
        margin-top: 0 !important; }
      .touch-device .navigation__list-item--last.click::before {
        margin-top: 0 !important; }
      .navigation__list-item--last .navigation__list--sub {
        margin-top: 8px; }
  .click-device .navigation__list-item--has-sub:hover {
    background: #8b0b10;
    border-radius: 8px; }
    @media (min-width: 768px) {
      .click-device .navigation__list-item--has-sub:hover {
        background: none; }
        .click-device .navigation__list-item--has-sub:hover::before {
          content: "";
          border-left: 8px solid transparent;
          border-bottom: 8px solid #c40f16;
          border-right: 8px solid transparent;
          position: absolute;
          left: 50%;
          margin-left: -6px;
          margin-top: 12px;
          top: 100%;
          transition: all 0.3s ease-in-out; } }
    .click-device .navigation__list-item--has-sub:hover .navigation__list--sub {
      display: block; }
    .click-device .navigation__list-item--has-sub:hover .navigation__list--sub::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 100%;
      height: 35px;
      width: 75%; }
    .click-device .navigation__list-item--has-sub:hover .navigation__list--sub .navigation__item {
      color: #ffffff; }
  .touch-device .navigation__list-item--has-sub.click {
    background: #8b0b10;
    border-radius: 8px; }
    @media (min-width: 768px) {
      .touch-device .navigation__list-item--has-sub.click {
        background: none; }
        .touch-device .navigation__list-item--has-sub.click::before {
          content: "";
          border-left: 8px solid transparent;
          border-bottom: 8px solid #c40f16;
          border-right: 8px solid transparent;
          position: absolute;
          left: 50%;
          margin-left: -6px;
          margin-top: 12px;
          top: 100%;
          transition: all 0.3s ease-in-out; } }
    .touch-device .navigation__list-item--has-sub.click .navigation__list--sub {
      display: block; }
    .touch-device .navigation__list-item--has-sub.click .navigation__list--sub::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 100%;
      height: 35px;
      width: 75%; }
    .touch-device .navigation__list-item--has-sub.click .navigation__list--sub .navigation__item {
      color: #ffffff; }
  .click-device .navigation__list-item:hover .navigation__item {
    text-decoration: none;
    color: #ffffff; }
    @media (min-width: 768px) {
      .click-device .navigation__list-item:hover .navigation__item {
        color: #fbd41a; } }
    .click-device .navigation__list-item:hover .navigation__item svg {
      transform: rotateX(180deg); }
    .click-device .navigation__list-item:hover .navigation__item .navigation__item-icon svg {
      transform: none; }
  .touch-device .navigation__list-item.click .navigation__item {
    text-decoration: none;
    color: #ffffff; }
    @media (min-width: 768px) {
      .touch-device .navigation__list-item.click .navigation__item {
        color: #fbd41a; } }
    .touch-device .navigation__list-item.click .navigation__item svg {
      transform: rotateX(180deg); }
    .touch-device .navigation__list-item.click .navigation__item .navigation__item-icon svg {
      transform: none; }
  .navigation__item {
    display: inline-flex;
    font-size: 1.75rem;
    font-weight: 800;
    line-height: 1.25em;
    color: #ffffff;
    padding: 5px 20px;
    margin-bottom: 5px;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased; }
    @media (min-width: 768px) {
      .navigation__item {
        padding: 5px 0;
        font-size: 0.625rem;
        margin-bottom: 0; } }
    @media (min-width: 992px) {
      .navigation__item {
        font-size: 0.75rem; } }
    @media (min-width: 1200px) {
      .navigation__item {
        font-size: 0.875rem; } }
    .navigation__item svg {
      display: inline-block;
      width: 1.2em;
      height: 1.2em;
      margin-left: 0.75em;
      fill: #ffffff;
      transition: all 0.3s ease-in-out; }
    .navigation__item--active {
      color: #fbd41a; }
    .navigation__item--highlight {
      color: #ffffff;
      background: #c40f16;
      display: inline-block; }
      @media (min-width: 768px) {
        .navigation__item--highlight {
          padding: 20px 15px 20px 10px; } }
      @media (min-width: 992px) {
        .navigation__item--highlight {
          padding: 25px 20px 24.2px 15px; } }
      @media (min-width: 1200px) {
        .navigation__item--highlight {
          padding: 32px 50px 29.6px 40px; } }
  .navigation__list--sub {
    display: none;
    padding: 20px 35px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out; }
    .navigation__list--sub .navigation__list-item {
      display: block;
      width: 100%; }
      .navigation__list--sub .navigation__list-item--address-start {
        margin-top: 0.5em; }
      .navigation__list--sub .navigation__list-item--address-end {
        margin-bottom: 0.5em; }
    .navigation__list--sub .navigation__item-text {
      transition: all 0.3s ease-in-out;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    .navigation__list--sub .navigation__item {
      font-weight: 600;
      font-size: 0.875rem;
      letter-spacing: 0.2em;
      margin: 0;
      margin-bottom: 0.5em;
      line-height: 1; }
      @media (pointer: fine), (min-width: 1200px) {
        .navigation__list--sub .navigation__item:hover {
          color: #ffffff;
          text-decoration: none; }
        .navigation__list--sub .navigation__item:hover .navigation__item-text {
          border-bottom: 1px solid white; } }
    .navigation__list--sub .navigation__item-text--static {
      color: #fbd41a;
      border-bottom: none; }
      @media (pointer: fine), (min-width: 1200px) {
        .navigation__list--sub .navigation__item-text--static:hover {
          text-decoration: none; } }
    .navigation__list--sub .navigation__item-icon {
      width: 29px;
      height: 29px;
      display: inline-block;
      vertical-align: middle;
      margin: -7px 1.25em -7px -15px; }
      .navigation__list--sub .navigation__item-icon svg {
        fill: #fbd41a;
        width: 100%;
        height: 100%; }
    @media (min-width: 768px) {
      .navigation__list--sub {
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: 20px;
        background: #c40f16;
        box-shadow: 0 22px 34px rgba(0, 0, 0, 0.479);
        color: #ffffff; } }
  .navigation__list--address {
    display: block;
    padding-left: 20px;
    padding-right: 20px; }
    .navigation__list--address .navigation__item {
      padding-left: 0; }
    @media (min-width: 768px) {
      .navigation__list--address {
        display: none;
        padding: 20px 35px; } }
  .click-device .header--scrolled .navigation__list-item--has-sub:hover::before {
    margin-top: 27px; }
  .touch-device .header--scrolled .navigation__list-item--has-sub.click::before {
    margin-top: 27px; }
  @media (min-width: 768px) {
    body .header--scrolled .navigation__list--sub {
      margin-top: 35px; } }
  @media (min-width: 768px) {
    body .header--scrolled .navigation__list-item--last .navigation__list--sub {
      margin-top: 8px; } }
  .navigation__badge {
    background: #c40f16;
    border-radius: 50%;
    display: none;
    font-size: 12px;
    height: 1.25em;
    line-height: 1.25em;
    margin-top: -0.65em;
    min-width: 1.25em;
    padding: 0 0.125em;
    text-align: center; }
    @media (min-width: 768px) {
      .navigation__badge {
        display: inline-block; } }

.main {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  flex: 1 1 100%; }

.container--plus {
  max-width: 1430px; }

.site {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 100%;
  align-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden; }
  @media (min-width: 768px) {
    .site {
      padding-left: 0;
      padding-right: 0;
      overflow: visible; } }

.footer {
  background-color: #ffffff;
  color: #000000;
  position: relative;
  z-index: 15;
  flex: 1 1 100%; }
  .footer a:not(.button):not(.pagination__control-link):not(.pagination__list-link) {
    color: #000000; }
  .footer__bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media (min-width: 768px) {
      .footer__bottom {
        padding-top: 40px;
        padding-bottom: 40px; } }
    .footer__bottom a:not(.button):not(.pagination__control-link):not(.pagination__list-link) {
      color: #000000; }
  .footer__title {
    margin-bottom: 1em;
    letter-spacing: 0.2em; }
  .footer__list {
    padding-left: 1.8em;
    line-height: 36px; }
  .footer__icon {
    width: 1.375rem;
    height: 1.375rem;
    margin: 0 0.5em 0 -1.9em;
    display: inline-flex;
    justify-content: center;
    vertical-align: middle; }
    .footer__icon svg {
      height: 100%;
      width: 100%;
      fill: #c40f16; }
  .footer__certificate {
    width: 100%; }
    .footer__certificate--large {
      width: auto;
      max-width: 100%; }
  .footer__certificates {
    margin-top: 50px;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .footer__certificates {
        margin-top: 20px; } }

.footer-bottom {
  padding-top: 15px;
  padding-bottom: 25px;
  border-top: 1px solid #000000;
  margin-top: 30px; }
  .footer-bottom__list-item {
    align-items: center;
    -webkit-font-smoothing: antialiased;
    color: #000000;
    font-size: 0.75rem;
    line-height: 1.66em;
    font-weight: 800;
    text-transform: uppercase;
    display: inline-flex;
    padding-right: 10px; }
    .footer-bottom__list-item::after {
      content: "";
      width: 0.25em;
      height: 0.25em;
      background: #000000;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px; }
    .footer-bottom__list-item:last-child::after {
      content: none; }
  .footer-bottom__item {
    color: inherit;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    line-height: 1em;
    transition: all 0.3s ease-in-out; }
    .footer-bottom__item:hover {
      text-decoration: none;
      border-bottom: 1px solid black; }
  .footer-bottom__reference {
    -webkit-font-smoothing: antialiased;
    color: #000000;
    display: block;
    font-size: 12px;
    font-weight: 800;
    margin-top: 1em;
    width: 100%;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .footer-bottom__reference {
        display: inline-block;
        margin-top: 0;
        width: auto; } }
  .footer-bottom__webnl {
    color: inherit;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    display: inline-block;
    line-height: 1em;
    transition: all 0.3s ease-in-out; }
    .footer-bottom__webnl:hover {
      text-decoration: none;
      border-bottom: 1px solid black; }

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 2rem 0; }
  .video-wrapper__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.hero {
  height: 70vh;
  position: relative;
  margin-left: -10px;
  margin-right: -10px; }
  @media (min-width: 768px) {
    .hero {
      margin-left: 0;
      margin-right: 0; } }
  .hero__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .hero__image::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6); }
  .hero__bg {
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%; }
  .hero__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .hero__content h1, .hero__content h2, .hero__content h3, .hero__content h4, .hero__content h5, .hero__content h6 {
      color: #ffffff; }

.text-link__text {
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 800;
  font-size: 0.875rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  line-height: 1em;
  transition: all 0.3s ease-in-out;
  display: inline-block; }

.text-link svg {
  height: 1em;
  width: 1.2em;
  fill: #c40f16;
  margin-left: 0.25em;
  transition: all 0.3s ease-in-out; }

.text-link:hover .text-link__text {
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid black; }

.text-link:hover svg {
  margin-left: 0.5em; }

.section {
  margin-bottom: 50px; }
  @media (min-width: 768px) {
    .section {
      margin-bottom: 100px; } }
  .section--double-mb {
    margin-bottom: 100px; }
    @media (min-width: 768px) {
      .section--double-mb {
        margin-bottom: 150px; } }

.home-content {
  padding-left: 10px;
  padding-right: 10px; }
  @media (min-width: 768px) {
    .home-content {
      padding-left: 0;
      padding-right: 0; } }

.page-header {
  transition: all 0.3s ease-in-out;
  position: relative; }
  .page-header__title {
    background: #fbd41a;
    padding: 25px;
    border-radius: 8px;
    display: inline-block; }
  .page-header--pull-up {
    margin-bottom: 100px; }
    @media (min-width: 768px) {
      .page-header--pull-up {
        margin-bottom: 150px; } }
    @media (min-width: 992px) {
      .page-header--pull-up {
        margin-bottom: 100px; } }
    .page-header--pull-up .page-header__title {
      position: absolute;
      transform: translateY(-50%);
      margin-top: -50px; }
      @media (min-width: 768px) {
        .page-header--pull-up .page-header__title {
          margin-top: -100px;
          padding: 40px; } }

@media (min-width: 768px) {
  .page-content {
    padding-left: 10px;
    padding-right: 10px; } }

@media (min-width: 992px) {
  .page-content {
    padding-left: 20px;
    padding-right: 20px; } }

@media (min-width: 1200px) {
  .page-content {
    padding-left: 30px;
    padding-right: 30px; } }

@media (min-width: 1600px) {
  .page-content {
    padding-left: 40px;
    padding-right: 40px; } }

.page-content form textarea,
.page-content form input {
  border-color: #616161; }
  .page-content form textarea:focus-within,
  .page-content form input:focus-within {
    border-color: #1d8ebf; }

.page-content form label:not(.checkbox-wrapper__input-wrapper):focus-within .page-content form__label {
  color: #1d8ebf; }

.form-wrapper {
  background-color: #fbd41a;
  box-shadow: 0 24px 44px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 15px 20px; }
  @media (min-width: 768px) {
    .form-wrapper {
      padding: 30px 40px; } }
  @media (min-width: 1200px) {
    .form-wrapper {
      padding: 50px 60px; } }
  @media (min-width: 1200px) {
    .form-wrapper--wide {
      padding-left: 115px;
      padding-right: 115px; } }
  .form-wrapper__title {
    margin-top: 0; }

.contact-person {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
  position: sticky;
  top: 100px;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .contact-person {
      margin-top: 0; } }
  .contact-person__row {
    margin-left: -6px;
    margin-right: -6px;
    display: flex;
    align-items: center; }
  .contact-person__col {
    padding-left: 6px;
    padding-right: 6px; }
  .contact-person__image {
    height: 140px;
    width: 140px;
    margin-bottom: 20px;
    border-radius: 50%; }
    @media (min-width: 768px) {
      .contact-person__image {
        height: 80px;
        width: 80px; } }
    @media (min-width: 992px) {
      .contact-person__image {
        height: 100px;
        width: 100px; } }
    @media (min-width: 1200px) {
      .contact-person__image {
        height: 120px;
        width: 120px; } }
    @media (min-width: 1600px) {
      .contact-person__image {
        height: 140px;
        width: 140px; } }
  .contact-person__title {
    color: #c40f16;
    font-size: 1.3rem;
    margin: 0 0 20px; }
  .contact-person__name {
    color: #000000; }
  .contact-person__function {
    color: #c40f16;
    display: block;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-size: 0.875rem; }
  .contact-person__contact {
    font-size: 1rem; }
    @media (min-width: 992px) {
      .contact-person__contact {
        font-size: 1.125rem; } }
  .contact-person__icon {
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.25em;
    display: inline-block; }
    .contact-person__icon svg {
      fill: #c40f16;
      max-width: 100%;
      max-height: 100%; }
  .contact-person__link {
    color: #000000;
    text-decoration: underline; }
  .contact-person__content {
    margin-top: 40px;
    background-color: #c40f16;
    color: #ffffff;
    border-radius: 8px;
    padding: 20px; }

.home-slider {
  position: relative;
  min-height: 70vh;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .home-slider__slides {
    height: 70vh;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .home-slider__slides > .flickity-viewport {
      height: 100% !important; }
  .home-slider .home-slide {
    position: absolute;
    top: 0;
    opacity: 0; }
    .home-slider .home-slide:first-child {
      opacity: 1; }
  .home-slider__slides.flickity-enabled .home-slide {
    opacity: 1; }

.home-slide {
  height: 70vh;
  position: relative;
  width: 100%; }
  .home-slide__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .home-slide__image::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6); }
  .home-slide__photo {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .home-slide__content {
    height: 100%;
    display: flex;
    flex: 1 1 100%;
    align-content: center;
    align-items: center; }
  .home-slide__title {
    color: #ffffff;
    font-size: 3.125rem; }
  .home-slide__subtitle {
    color: #ffffff;
    font-size: 0.9375rem; }

.home-slider-nav {
  position: relative;
  z-index: 1;
  margin-top: -50px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  margin-left: -20px;
  margin-right: -20px;
  /* stylelint-disable */
  /* stylelint-enable */ }
  @media (min-width: 768px) {
    .home-slider-nav {
      margin: -70px 0 0; } }
  .home-slider-nav .flickity-viewport {
    width: 100%;
    height: auto !important; }
  .home-slider-nav .flickity-slider {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    transform: none !important; }
  .home-slider-nav__slide {
    background: #fbd41a;
    display: inline-flex;
    flex-wrap: wrap;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    position: relative;
    justify-content: center;
    /* stylelint-disable */
    position: relative !important;
    left: auto !important;
    /* stylelint-enable */
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media (min-width: 768px) {
      .home-slider-nav__slide {
        padding: 20px 15px;
        justify-content: start; } }
    @media (min-width: 1600px) {
      .home-slider-nav__slide {
        padding: 40px 20px; } }
    .home-slider-nav__slide::after {
      content: "";
      position: absolute;
      bottom: -10px;
      opacity: 0;
      left: 20px;
      right: 20px;
      border-bottom: 2px solid #c40f16;
      transition: all 0.3s ease-in-out; }
    .home-slider-nav__slide:hover {
      background: #fdc71e; }
    .home-slider-nav__slide.is-selected {
      background: #ffffff; }
      .home-slider-nav__slide.is-selected::after {
        bottom: 2px;
        opacity: 1; }
  .home-slider-nav__icon {
    display: inline-block;
    height: 30px; }
    @media (min-width: 768px) {
      .home-slider-nav__icon {
        margin: 0 0 10px;
        height: 55px; } }
    @media (min-width: 992px) {
      .home-slider-nav__icon {
        height: auto; } }
    @media (min-width: 1200px) {
      .home-slider-nav__icon {
        margin: 0 10px 0 0;
        width: 60px; } }
    .home-slider-nav__icon svg {
      fill: #c40f16; }
    .home-slider-nav__icon--fire svg {
      height: 23px;
      width: 24px; }
      @media (min-width: 768px) {
        .home-slider-nav__icon--fire svg {
          height: 34.5px;
          width: 36px; } }
      @media (min-width: 1200px) {
        .home-slider-nav__icon--fire svg {
          height: 46px;
          width: 48px; } }
    .home-slider-nav__icon--run svg {
      height: 25px;
      width: 13px; }
      @media (min-width: 768px) {
        .home-slider-nav__icon--run svg {
          height: 37.5px;
          width: 19.5px; } }
      @media (min-width: 1200px) {
        .home-slider-nav__icon--run svg {
          height: 47px;
          width: 25px; } }
    .home-slider-nav__icon--sprinkler svg {
      height: 25px;
      width: 25px; }
      @media (min-width: 768px) {
        .home-slider-nav__icon--sprinkler svg {
          height: 37.5px;
          width: 37.5px; } }
      @media (min-width: 1200px) {
        .home-slider-nav__icon--sprinkler svg {
          height: 48px;
          width: 48px; } }
    .home-slider-nav__icon--file svg {
      height: 24px;
      width: 17px; }
      @media (min-width: 768px) {
        .home-slider-nav__icon--file svg {
          height: 36px;
          width: 25.5px; } }
      @media (min-width: 1200px) {
        .home-slider-nav__icon--file svg {
          height: 46px;
          width: 33px; } }
    .home-slider-nav__icon--tools svg {
      height: 25px;
      width: 25px; }
      @media (min-width: 768px) {
        .home-slider-nav__icon--tools svg {
          height: 37.5px;
          width: 37.5px; } }
      @media (min-width: 1200px) {
        .home-slider-nav__icon--tools svg {
          height: 46px;
          width: 46px; } }
  .home-slider-nav__content {
    display: none;
    flex: 0 0 100%; }
    @media (min-width: 768px) {
      .home-slider-nav__content {
        display: inline-block; } }
    @media (min-width: 1200px) {
      .home-slider-nav__content {
        flex: 0 0 auto; } }
  .home-slider-nav__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.8rem; }
  .home-slider-nav__sub-title {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.9rem; }

.home-slider-content {
  box-shadow: 0 24px 44px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 8px 8px;
  margin-left: -20px;
  margin-right: -20px;
  /* stylelint-disable */
  /* stylelint-enable */ }
  @media (min-width: 768px) {
    .home-slider-content {
      margin-left: 0;
      margin-right: 0; } }
  .home-slider-content .flickity-slider {
    transform: none !important; }
  .home-slider-content__slide {
    left: 0 !important;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: -1;
    width: 100%;
    padding: 10px 20px; }
    @media (min-width: 768px) {
      .home-slider-content__slide {
        padding: 10px 40px 40px; } }
    @media (min-width: 1200px) {
      .home-slider-content__slide {
        padding: 0 40px 40px; } }
    .home-slider-content__slide h1, .home-slider-content__slide h2, .home-slider-content__slide h3, .home-slider-content__slide h4, .home-slider-content__slide h5, .home-slider-content__slide h6 {
      color: #c40f16; }
    .home-slider-content__slide.is-selected {
      opacity: 1;
      z-index: 0; }

.block {
  padding: 30px 25px;
  border-radius: 8px;
  color: #ffffff;
  /* stylelint-disable */
  /* stylelint-enable */ }
  @media (min-width: 768px) {
    .block {
      padding: 60px 40px; } }
  .block h1:first-child, .block h2:first-child, .block h3:first-child {
    margin-top: 0; }
  .block h1, .block h2, .block h3, .block h4, .block h5, .block h6 {
    color: inherit; }
  .block--branches {
    background-color: #950b11;
    margin-left: -20px;
    margin-right: -20px; }
    @media (min-width: 768px) {
      .block--branches {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 1200px) {
      .block--branches {
        padding: 85px 55px; } }
  .block--vacancies {
    background-color: #950b11;
    margin-top: -150px; }
    @media (min-width: 768px) {
      .block--vacancies {
        margin-top: -200px; } }
    @media (min-width: 768px) {
      .block--vacancies {
        margin-top: -230px; } }
  .block--filter {
    background-color: #950b11; }
  .block__content {
    -webkit-font-smoothing: antialiased; }
  .block--contact {
    background-color: #c4c4c4;
    color: #000000;
    padding: 20px;
    margin-bottom: 20px; }
    .block--contact .block__header {
      color: #ffffff;
      margin-top: 0; }

.block-links {
  display: flex;
  padding-right: 0;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px; }

.block-link {
  display: inline-block;
  margin: 5px;
  width: 100%; }
  @media (min-width: 768px) {
    .block-link {
      width: auto; } }
  .block-link__link {
    background: #e3e3e3;
    color: #000000;
    border-radius: 8px;
    display: flex;
    padding: 15px 20px;
    transition: all 0.3s ease-in-out; }
    .block-link__link:hover {
      text-decoration: none;
      background: #cacaca; }
      .block-link__link:hover .block-link__icon:not(.block-link__icon--dot) {
        margin: 0 0.05em 0 0.3em; }
    .block-link__link--red {
      background: #c40f16;
      color: #ffffff; }
      .block-link__link--red:hover {
        background: #ac0d13;
        color: #ffffff; }
      .block-link__link--red .block-link__icon svg {
        fill: #fbd41a; }
    .block-link__link--caps {
      text-transform: uppercase;
      letter-spacing: 0.2em;
      font-size: 0.875rem; }
  .block-link__icon {
    width: 1.25rem;
    height: 0.75rem;
    display: inline-block;
    margin: 0 0.35em 0 0;
    transition: all 0.3s ease-in-out;
    flex: 0 0 auto; }
    .block-link__icon svg {
      width: 100%;
      height: 0.75rem;
      fill: #c40f16; }
    .block-link__icon--dot {
      width: 0.85em;
      height: 0.85em;
      border-radius: 50%;
      background: #616161;
      vertical-align: middle;
      align-self: center; }
  .block-link--active .block-link__icon--dot {
    background: #fbd41a; }
  .block-link__text {
    color: inherit; }

.project-filters__item {
  display: none;
  transition: all 0.3s ease-in-out; }
  @media (min-width: 992px) {
    .project-filters__item {
      display: inline-block; } }

.project-filters--active .project-filters__item {
  display: block; }

.project-filters__icon {
  transition: all 0.3s ease-in-out;
  display: inline-block;
  transform: rotateX(0deg);
  height: 1em;
  width: 1.2em; }
  @media (min-width: 992px) {
    .project-filters__icon {
      display: none; } }
  .project-filters__icon svg {
    display: inline-block;
    width: 1.2em;
    height: 1em;
    fill: #ffffff; }
  .project-filters__icon--active {
    transform: rotateX(180deg); }

.projects-slider {
  margin-right: -20px; }
  @media (min-width: 992px) {
    .projects-slider {
      margin-right: 0; } }
  .projects-slider__slide {
    margin-right: 5%;
    width: 85%; }
    @media (min-width: 992px) {
      .projects-slider__slide {
        width: 45%;
        margin-left: 5.05%; } }
  .projects-slider__title {
    margin-top: 0; }
  .projects-slider__nav {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 50px; }
  .projects-slider__nav-item {
    background: none;
    border: none;
    height: 12px;
    padding: 0;
    margin: 0 10px;
    width: 20px;
    line-height: 0; }
    .projects-slider__nav-item:hover, .projects-slider__nav-item:focus, .projects-slider__nav-item:active {
      outline: none; }
    .projects-slider__nav-item svg {
      fill: #000000;
      transition: all 0.3s ease-in-out;
      height: 12px;
      vertical-align: unset;
      width: 20px; }
    .projects-slider__nav-item:hover svg {
      fill: #c40f16; }

.project-card {
  width: 100%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .project-card {
      margin-bottom: 40px; } }
  .project-card__image {
    width: 100%;
    height: auto; }
  .project-card__content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 25px 30px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 37.55%, #000000 100%);
    display: flex;
    align-content: flex-end;
    flex-wrap: wrap;
    transition: all 0.3s ease-in-out; }
  .project-card__title {
    color: #ffffff;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    flex: 1 1 100%; }
  .project-card__link {
    margin-top: 0.2em;
    flex: 1 1 100%;
    color: #c40f16; }
  .project-card:hover {
    text-decoration: none; }
    .project-card:hover .project-card__title {
      margin-bottom: 1em; }
    .project-card:hover .project-card__content {
      padding-bottom: 35px; }

.pagination {
  display: flex;
  justify-content: flex-end; }
  .pagination__list {
    margin: 0; }
  .pagination__list-item {
    display: inline-block;
    padding-left: 12px; }
  .pagination__control-link, .pagination__list-link {
    background: #ffffff;
    color: #000000;
    border: 1px solid #c40f16;
    padding: 0.865em 1.05em;
    line-height: 1em;
    min-height: 0; }
    .pagination__control-link:hover, .pagination__list-link:hover {
      text-decoration: none;
      border: 1px solid #c40f16;
      color: #ffffff;
      background: #c40f16; }
    .pagination__control-link--active, .pagination__list-link--active {
      background: #c40f16;
      color: #fbd41a; }
    .pagination__control-link--control, .pagination__list-link--control {
      background: #c40f16;
      color: #ffffff; }
      .pagination__control-link--control:hover, .pagination__list-link--control:hover {
        background-color: #d6b100;
        color: #000000; }
  .pagination__control-link--inactive {
    display: none; }
