.page-content {
    @include media-breakpoint-up(md) {
        padding-left: 10px;
        padding-right: 10px;
    }

    @include media-breakpoint-up(lg) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @include media-breakpoint-up(xl) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @include media-breakpoint-up(xxl) {
        padding-left: 40px;
        padding-right: 40px;
    }

    form {

        $form: &;

        textarea,
        input {
            border-color: map-deep-get($colors, "gray", "default");

            &:focus-within {
                border-color: map-deep-get($colors, "brand", "blue");
            }
        }

        label:not(.checkbox-wrapper__input-wrapper) {
            &:focus-within {
                #{$form}__label {
                    color: map-deep-get($colors, "brand", "blue");
                }
            }
        }
    }
}
