.home-slider-nav {
    position: relative;
    z-index: 1;
    margin-top: -50px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    margin-left: -#{($grid-gutter-width / 2) + 10};
    margin-right: -#{($grid-gutter-width / 2) + 10};

    @include media-breakpoint-up(md) {
        margin: -70px 0 0;
    }

    /* stylelint-disable */
    .flickity-viewport {
        width: 100%;
        height: auto !important;
    }

    .flickity-slider {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        transform: none !important;
    }
    /* stylelint-enable */

    &__slide {
        background: map-deep-get($colors, "brand", "secondary");
        display: inline-flex;
        flex-wrap: wrap;
        padding: 20px;
        transition: $transition-default;
        cursor: pointer;
        position: relative;
        justify-content: center;

        // vendor overwrite
        /* stylelint-disable */
        position: relative !important;
        left: auto !important;
        /* stylelint-enable */

        @include media-breakpoint-up(md) {
            padding: 20px 15px;
            justify-content: start;
        }

        @include media-breakpoint-up(xxl) {
            padding: 40px 20px;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            opacity: 0;
            left: 20px;
            right: 20px;
            border-bottom: 2px solid map-deep-get($colors, "brand", "primary");
            transition: $transition-default;
        }

        &:hover {
            background: lighten(saturate(adjust-hue(map-deep-get($colors, "brand", "secondary"), -4), 1.67), 1.18);
        }

        /* stylelint-disable */
        &.is-selected {
            background: map-deep-get($colors, "white", "default");

            &::after {
                bottom: 2px;
                opacity: 1;
            }
        }
        /* stylelint-enable */
    }

    &__icon {
        display: inline-block;
        height: 30px;

        @include media-breakpoint-up(md) {
            margin: 0 0 10px;
            height: 55px;
        }

        @include media-breakpoint-up(lg) {
            height: auto;
        }

        @include media-breakpoint-up(xl) {
            margin: 0 10px 0 0;
            width: 60px;
        }

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }

        &--fire svg {
            height: 23px;
            width: 24px;

            @include media-breakpoint-up(md) {
                height: #{23px * 1.5};
                width: #{24px * 1.5};
            }

            @include media-breakpoint-up(xl) {
                height: 46px;
                width: 48px;
            }
        }

        &--run svg {
            height: 25px;
            width: 13px;

            @include media-breakpoint-up(md) {
                height: #{25px * 1.5};
                width: #{13px * 1.5};
            }

            @include media-breakpoint-up(xl) {
                height: 47px;
                width: 25px;
            }
        }

        &--sprinkler svg {
            height: 25px;
            width: 25px;

            @include media-breakpoint-up(md) {
                height: #{25px * 1.5};
                width: #{25px * 1.5};
            }

            @include media-breakpoint-up(xl) {
                height: 48px;
                width: 48px;
            }
        }

        &--file svg {
            height: 24px;
            width: 17px;

            @include media-breakpoint-up(md) {
                height: #{24px * 1.5};
                width: #{17px * 1.5};
            }

            @include media-breakpoint-up(xl) {
                height: 46px;
                width: 33px;
            }
        }

        &--tools svg {
            height: 25px;
            width: 25px;

            @include media-breakpoint-up(md) {
                height: #{25px * 1.5};
                width: #{25px * 1.5};
            }

            @include media-breakpoint-up(xl) {
                height: 46px;
                width: 46px;
            }
        }
    }

    &__content {
        display: none;
        flex: 0 0 100%;

        @include media-breakpoint-up(md) {
            display: inline-block;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 auto;
        }
    }

    &__title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.8rem;
    }

    &__sub-title {
        color: rgba(map-deep-get($colors, "black", "default"), 0.5);
        font-size: 0.9rem;
    }
}
