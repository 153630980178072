.header {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    //height: map_get($header-height, "small");
    transition: $transition-default;
    $header: &;

    &__wrapper {
        //background: map-deep-get($colors, "white", "default");
        overflow: hidden;

        @include media-breakpoint-up(md) {
            transition: $transition-default;
            background-color: transparent;
            height: auto;
            overflow: visible;
        }
    }

    &--scrolled {
        &:not(.header--expanded){
            background: map-deep-get($colors, "gray", "default");

            @include media-breakpoint-up(md) {
                background: none;
            }
        }

        #{$header}__wrapper {
            @include media-breakpoint-up(md) {
                background: map-deep-get($colors, "gray", "default");
            }
        }

        #{$header}__start svg {
            fill: map-deep-get($colors, "gray", "default");
        }
    }

    &--expanded {
        background: map-deep-get($colors, "brand", "primary");
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    &__logo {
        transition: $transition-default;
        padding-top: 5px;
        padding-bottom: 5px;
        display: inline-flex;
        position: relative;
        align-items: center;
        align-content: center;

        @include media-breakpoint-up(md) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &__logo-image {
        height: #{(( 54 / 250) * 175) * 1px};
        width: 175px;

        @include media-breakpoint-up(md) {
            height: 40px;
            width: 40px;
        }

        @include media-breakpoint-up(lg) {
            height: #{(( 54 / 250) * 200) * 1px};
            width: 200px;
        }

        @include media-breakpoint-up(xl) {
            height: #{(( 54 / 250) * 220) * 1px};
            width: 220px;
        }

        @include media-breakpoint-up(xxl) {
            height: 54px;
            width: 250px;
        }
    }

    &__start,
    &__end {
        display: inline-block;

        svg {
            fill: transparent;
            transition: $transition-default;

            @include media-breakpoint-up(md) {
                height: 52px;
                width: 30px;
            }

            @include media-breakpoint-up(lg) {
                height: 65px;
                width: 38px;
            }

            @include media-breakpoint-up(xl) {
                height: 81px;
                width: 46px;
            }
        }
    }

    &__start {
        margin-right: -1px;
    }

    &__end {
        margin-left: -1px;

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &__menu-toggle {
        float: right;
        position: relative;
    }
}