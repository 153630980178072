.contact-person {
    border: 1px solid map-deep-get($colors, "gray", "lighter");
    border-radius: 8px;
    padding: 20px;
    background-color: map-deep-get($colors, "white", "default");
    position: sticky;
    top: 100px;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }

    &__row {
        margin-left: -6px;
        margin-right: -6px;
        display: flex;
        align-items: center;
    }

    &__col {
        padding-left: 6px;
        padding-right: 6px;
    }

    &__image {
        height: 140px;
        width: 140px;
        margin-bottom: 20px;
        border-radius: 50%;

        @include media-breakpoint-up(md) {
            height: 80px;
            width: 80px;
        }

        @include media-breakpoint-up(lg) {
            height: 100px;
            width: 100px;
        }

        @include media-breakpoint-up(xl) {
            height: 120px;
            width: 120px;
        }

        @include media-breakpoint-up(xxl) {
            height: 140px;
            width: 140px;
        }
    }

    &__title {
        color: map-deep-get($colors, "brand", "primary");
        font-size: 1.3rem;
        margin: 0 0 20px;
    }

    &__name {
        color: map-deep-get($colors, "black", "default");
    }

    &__function {
        color: map-deep-get($colors, "brand", "primary");
        display: block;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-size: rem-calc(14);
    }

    &__contact {
        font-size: rem-calc(16);

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(18);
        }
    }

    &__icon {
        width: 1.2em;
        height: 1.2em;
        margin-right: 0.25em;
        display: inline-block;

        svg {
            fill: map-deep-get($colors, "brand", "primary");
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__link {
        color: map-deep-get($colors, "black", "default");
        text-decoration: underline;
    }

    &__content {
        margin-top: 40px;
        background-color: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "white", "default");
        border-radius: 8px;
        padding: 20px;
    }
}
